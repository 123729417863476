import React, { useMemo, useState } from 'react';

import {
  Box,
  Button,
  Flex,
  Image,
  useTheme,
  useMediaQuery,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';

import Logo from '@/assets/images/daily-faceoff-survivor-logo.svg';

import { MediaQueriesEnum } from '@/enum/mediaQueries.enum';
import { RoutesEnum } from '@/enum/routes.enum';

const Header: React.FC = () => {
  const [isDesktop] = useMediaQuery(MediaQueriesEnum.isDesktop, { ssr: false });
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();

  const [logoLoaded, setLogoLoaded] = useState(false);

  const goToHome = () => {
    navigate(RoutesEnum.HOME);
  };

  const isHeaderShown = useMemo(
    () => pathname !== RoutesEnum.SSOENTRY,
    [pathname],
  );

  return (
    <Box
      as='header'
      display={isHeaderShown ? 'block' : 'none'}
      left='0'
      position='fixed'
      top='0'
      w='100%'
      zIndex='997'
    >
      <Box
        background={theme.colors.header.primaryBg}
        h={{ base: '70px', lg: '96px' }}
        p='8px 20px 0'
        w='100%'
      >
        <Flex
          align='center'
          h='100%'
          justify='space-between'
          position='relative'
          w='100%'
        >
          <Box mx='auto' zIndex='1'>
            <Button onClick={goToHome} p='0' variant='ghost'>
              <Image
                alt=''
                display={logoLoaded ? 'block' : 'none'}
                onLoad={() => setLogoLoaded(true)}
                src={Logo}
              />
            </Button>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
