import { Helmet, HelmetProvider as Provider } from 'react-helmet-async';

export const MetadataProvider = () => {
  return (
    <Provider>
      <Helmet>
        <title>Wendy&apos;s Daily Faceoff Survivor</title>
        <link href='https://fonts.googleapis.com' rel='preconnect' />
        <link href='https://fonts.gstatic.com' rel='preconnect' />
        <link
          href='https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'
          rel='stylesheet'
        />
      </Helmet>
    </Provider>
  );
};
